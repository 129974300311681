import React, { useEffect, useState } from "react";
import { AccountObj } from "../../Models/Account";
import { CreateAccount, CreateAccountGoogle } from "../../Services/Authentication";
import { Button, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import Subscription from "./subscription";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import Header from "./layout/header";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import BuutonGoogle from "./button-google";

const Url = process.env.REACT_APP_URl;


const SignUp: React.FunctionComponent<{}> = (props) => {

    const storedSubscription = localStorage.getItem('subscription');
    const parsedSubscription = storedSubscription ? JSON.parse(storedSubscription) : "";
    const [selectedSubscription, setSelectedSubscription] = useState<any>(parsedSubscription ? parsedSubscription : { "priceId": "free", "subscription": "free" });
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState<AccountObj>({});
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState<string>();
    const [showPassword, setShowPassword] = useState(false);
    const [colorSnackbar, setColorSnackbar] = useState<any>("");

    const HandleClickGoogle = (token: any) => {
        
        CreateAccountGoogle(token).then(resp => {
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
                event: "sign up",
                userId: resp.user._id,
                userEmail: resp.user.email,
                loginMethod: "Google"
            });
            navigate('/Dashboard')

        }).catch((resp) => {
            setColorSnackbar("error")
            setOpenSnackbar(true)
            setMessage(resp.response.data.error)
        })
    }


    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handlePyment = async (value: any, id: any) => {

        const stripe = await loadStripe('pk_live_51PFD6iGzYmczk5yZEgPY10US28UcaFf1YEysZP9SVH0KSeNRGtwt0JtHal3YiXVwq9ZaBOBow5iw6MCs2E62ZwA400A2R7JaDG');
        const { data: { sessionId } } = await axios.post(Url + "/api/create-checkout-session", {
            accountId: id,
            priceId: value
        });
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                console.error(error);
            }
        } else {
            console.error('Stripe has not loaded');
        }
    };

    const handleClose = () => {
        setOpen(false)
        navigate("/login")
    }

    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleConfirmPassword = (e: any) => {
        const { value } = e.target;
        setConfirmPassword(value)
    }

    const validateForm = (): boolean => {
        const newErrors: any = {};

        if (!formData.first_name) {
            newErrors.first_name = 'Please enter your First Name';
        }

        if (!formData.last_name) {
            newErrors.last_name = 'Please enter your Last Name';
        }

        if (!formData.email) {
            newErrors.email = 'Please enter your email address';
        } else if (!/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/.test(formData.email)) {
            newErrors.email = 'Please enter your email in the correct format';
        }

        if (!formData.password) {
            newErrors.password = 'Please enter your password';
        } else if (/^(?=.[A-Za-z])(?=.\d)[A-Za-z\d@#$%^&*!?]{8,}$/.test(formData.password)) {
            newErrors.password = 'Password must be at least 8 characters, one letter, one number';
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = 'Confirmation password is required';
        } else if (formData.password !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleCreate = (event: any) => {
        event.preventDefault();
        const updatedFormData = {
            ...formData,
            subscription: selectedSubscription.subscription,
        };
        if (validateForm()) {
            setErrors({})
            CreateAccount(updatedFormData).then(resp => {

                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({
                    event: "sign up",
                    userId: resp.account._id,
                    userEmail: resp.account.email,
                    loginMethod: "Classic"
                });

                if (selectedSubscription && (selectedSubscription.subscription !== "free")) {
                    handlePyment(selectedSubscription ? selectedSubscription.priceId : null, resp.account._id)
                    localStorage.removeItem('subscription')
                    setOpen(true)
                } else {
                    localStorage.removeItem('subscription')
                    setOpen(true)
                }


            }).catch((resp) => {
                setColorSnackbar("error")
                setOpenSnackbar(true)
                setMessage(resp.response.data.error)
            })
        }
    }

    return (
        <>
            <Header />
            <div className="login-sec">
                <div className="container">
                    <div className="signup-form marg-login dir-rtl">
                        <div className="row">
                            <div className="col-md-7 order-11">
                                <form className="" onSubmit={handleCreate}>
                                    <div className="">
                                        <h2 className="title-32">{t("Create an account")}</h2>
                                        <p className="label color-333333">{t("Already have an account?")} <a href="/login" className="link-log">{t("log in")}</a></p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="name" className="label mb-3">{t("First name")}</label>
                                                <input type="text" className="form-control" name="first_name" value={formData.first_name || ""}
                                                    onChange={handleChange} />
                                                {errors.first_name && <span className="error">{t(`${errors.first_name}`)}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="name" className="label mb-3">{t("Last name")}</label>
                                                <input type="text" className="form-control" name="last_name" value={formData.last_name || ""}
                                                    onChange={handleChange} />
                                                {errors.last_name && <span className="error">{t(`${errors.last_name}`)}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className="label mb-3">{t("Email Address")}</label>
                                        <input type="email" className="form-control" value={formData.email || ""}
                                            name="email" onChange={handleChange} />
                                        {errors.email && <span className="error">{t(`${errors.email}`)}</span>}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group mb-3">
                                                <label htmlFor="Password" className="label mb-3">{t("Password")}</label>
                                                <input type={showPassword ? "text" : "password"} className="form-control" name="password" value={formData.password || ""}
                                                    onChange={handleChange} />
                                                {errors.password && <span className="error">{t(`${errors.password}`)}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group mb-3">
                                                <label htmlFor="Confirm" className="label mb-3">{t("Confirm your password")}</label>
                                                <input type={showPassword ? "text" : "password"} className="form-control" value={confirmPassword || ""}
                                                    onChange={handleConfirmPassword} />
                                                {errors.confirmPassword && <span className="error">{t(`${errors.confirmPassword}`)}</span>}
                                            </div>
                                        </div>
                                        <p className="label label-666 mt-3">{t("Use 8 or more characters with")}</p>
                                        <div className="d-flex justify-content-start">
                                            <input className="form-check-input  marg-right-10" type="checkbox" onChange={toggleShowPassword} value="" />
                                            <label className="form-check-label ">
                                                <p className="label color-333333"> {t("Show password")}</p>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="sign-a">
                                        <a href="/login" className="link-log"> {t("log in instead")}</a>
                                        <button type="submit" className="btn mb-3 mt-3" id="sign-up" >{t("Create an account")}</button>
                                        <GoogleOAuthProvider clientId="125672872979-gq1lv9nt2krppu0fn7d82qgfofbb8svh.apps.googleusercontent.com">
                                            <BuutonGoogle onCreateAccount={HandleClickGoogle} text={"Sign Up with Google"} />
                                        </GoogleOAuthProvider>
                                    </div>

                                </form >
                            </div >
                            <div className="col-md-5 d-flex align-items-center order-00">
                                <img src="/assets/images-v2/ai-room-staging.webp" alt="contact" className="img-login" />
                            </div>
                        </div>

                    </div >

                </div >
            </div >


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dir-rtl"
            >
                <DialogContent>
                    <DialogContentText>
                        <h4 className='msg-sign'>
                            {t("Your account has been created successfully! Please check")}
                        </h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="btn-holder ">
                        <button className="btn-2" type="submit" onClick={handleClose}>{t("Close")}</button>
                    </div>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={colorSnackbar || "info"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {t(`${message}`)}
                </Alert>
            </Snackbar>
        </>
    )
}
export default SignUp;