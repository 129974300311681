import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateAccountGoogle, login } from "../../Services/Authentication";
import { useTranslation } from "react-i18next";
import Header from "./layout/header";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import BuutonGoogle from "./button-google";

const Login: React.FunctionComponent<{}> = (props) => {


    const [formData, setFormData] = useState<any>({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<any>({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<string>();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const HandleClickGoogle = (token: any) => {
        CreateAccountGoogle(token).then(resp => {
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
                event: "login",
                userId: resp.user._id,
                userEmail: resp.user.email,
                loginMethod: "Google"
            });
            console.log(resp)
            navigate('/Dashboard')
        }).catch((resp) => {
            setErrorMessage(resp.response.data.error)
        })
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (formData.email && (!/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/.test(formData.email))) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        } else {
            setErrors({})
            login(formData).then(res => {
                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({
                    event: "login",
                    userId: res.user._id,
                    userEmail: res.user.email,
                    loginMethod: "Classic"
                });
                navigate('/Dashboard')
                // window.location.reload()
            }).catch(err => {
                console.log(err.response.data.loginValue.message)
                setErrorMessage(err.response.data.loginValue.message);
            })
            setTimeout(() => {
                setErrorMessage("");
            }, 3000)
        }
    }

    return (
        <>
            <Header />

            <div className="dir-rtl">
                <div className="login-sec" >
                    <div className="row text-center">
                        <h2 className="title-32">{t("Login")}</h2>
                    </div>
                    <div className="row marg-login justify-content-center">
                        <div className="col-md-6">
                            <form className="signup-form" onSubmit={handleLogin}>
                                {errorMessage && <div className="alert alert-danger" role="alert">{t(`${errorMessage}`)}</div>}
                                <p className="label color-333333">*{t("Required field")}</p>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="label mb-3">*{t("Email Address")}</label>
                                    <input type="email" className="form-control" value={formData.email} onChange={handleChangeEmail} />
                                    {errors.email && <span className="error">{t(`${errors.email}`)}</span>}
                                </div>
                                <div className="form-group  position-relative  mb-3">
                                    <label htmlFor="password" className="label mb-3">*{t("Password")}</label>
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} style={{
                                            paddingRight: "45px",
                                        }} className="form-control" value={formData.password} onChange={handleChangePassword} />
                                        <button
                                            type="button"
                                            className=" eye-slash"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                                        </button>
                                    </div>
                                    {errors.password && <span className="error">{t(`${errors.password}`)}</span>}
                                </div>
                                <div className="d-flex justify-content-start">
                                    <input className="form-check-input marg-right-10" type="checkbox" value="" />
                                    <label className="form-check-label d-flex">
                                        <p className="label color-333333 marg-right-10"> {t("Keep me sign in")}</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_66_341)">
                                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z" fill="#333333" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_66_341">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </label>
                                </div>

                                <div className="sign-a" >
                                    <a href="/account/reset/password" className="label color-33-bold mb-3">{t("Forget your password?")}</a>
                                    <a href="/sign-up" className="label color-33-bold" onClick={() => { localStorage.removeItem('subscription'); }}>{t("Don't have an account ? ")}</a>
                                    <button type="submit" className="btn mt-3 mb-3" id="sign-in">{t("Login")}</button>
                                    <GoogleOAuthProvider clientId="125672872979-gq1lv9nt2krppu0fn7d82qgfofbb8svh.apps.googleusercontent.com">
                                        <BuutonGoogle onCreateAccount={HandleClickGoogle} text={"Sign In with Google"} />
                                    </GoogleOAuthProvider>
                                </div>

                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
export default Login;